<template>
  <div id="call-to-action" v-if="ready">
    <v-card color="#00b4d8" v-if="moment(dateAfter).isAfter()">
      <v-card-title class="white--text mt-8 break-word">
        <div class="d-flex d-sm-none">
          <v-icon size="72" color="white">mdi-alert-circle</v-icon>
          <p
            style="font-size: 36px; letter-spacing: 0.5px; margin: 0 0 0 0.25em;"
          >
            <span v-if="dateBefore.isAfter(moment(), 'day')"
              >Important hearing on Wednesday!</span
            >
            <span v-if="dateBefore.isSame(moment(), 'day')"
              >Important hearing TOMORROW!</span
            >
            <span v-if="dateMeeting.isSame(moment(), 'day')"
              >Important hearing TONIGHT!</span
            >
          </p>
        </div>
        <v-icon class="d-none d-sm-flex" size="48" color="white"
          >mdi-alert-circle</v-icon
        >
        <p
          class="d-none d-sm-flex"
          style="font-size: 36px; letter-spacing: 0.5px; margin: 0 0 0 0.25em;"
        >
          <span v-if="dateBefore.isAfter(moment(), 'day')"
            >Important hearing on Wednesday!</span
          >
          <span v-if="dateBefore.isSame(moment(), 'day')"
            >Important hearing TOMORROW!</span
          >
          <span v-if="dateMeeting.isSame(moment(), 'day')"
            >Important hearing TONIGHT!</span
          >
        </p>
      </v-card-title>

      <v-divider color="white"></v-divider>

      <v-card-text class="my-2 pl-8 pr-6">
        <div class="meeting-name">
          Planning Board
        </div>
        <div class="meeting-date">
          Wednesday, May 18th at 7pm
        </div>
        <div class="meeting-details">
          <p>
            The Marina is going before the Planning Board this Wednesday, May
            18th at 7pm. We'd love a full house again! Please join us on
            Wednesday either in-person (at Town Hall in
            <a href="img/1820-map.png" target="_blank">the 1820 Court Room</a>)
            or via zoom (click
            <a href="https://zoom.us/j/91797025919" target="_blank">here</a> and
            then enter Passcode 028241).
          </p>
          <p>
            Yes, they are going to the Planning Board despite there being a
            demolition delay. And yes, it is despite the fact
            <a
              href="#" @click="popup()"
              >their existing plans</a
            >
            have been picked apart by every Commission they've gone in front of
            to date. And finally, again yes, it is despite the fact they have
            reported to us&mdash;and to different town officials&mdash;that they
            are currently seriously considering the common ground proposals our
            team presented to them on April 11th.
          </p>
          <p>
            Despite all of that, they are marching forward with their existing
            plans to the Planning Board.
            <strong
              >And so we are asking all of you to please attend on Wednesday,
              May 18th at 7pm to show that you care!</strong
            >
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CallToAction",
  data: () => ({
    ready: false,
    dateBefore: {},
    dateMeeting: {}
  }),
  methods: {
    popup() {
      const link = "plans_04-13-2022";
      this.$hub.$emit("openPdf", link, "timeline");
    }
  },
  mounted() {
    this.dateBefore = this.moment("2022-05-17");
    this.dateMeeting = this.moment("2022-05-18");
    this.dateAfter = this.moment("2022-05-19");
    this.ready = true;
  }
};
</script>

<style lang="scss">
#call-to-action {
  margin: 2em 0 1em;
  .meeting-name {
    font-size: 26px;
    font-weight: bold;
    line-height: 125%;
  }
  .meeting-date {
    font-size: 23px;
    font-weight: bold;
    line-height: 125%;
  }
  .meeting-details {
    margin: 1em 0;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 150%;
    a {
      color: white;
    }
  }
  .break-word {
    word-break: break-word !important;
  }
  .link {
    color: #03045e;
    font-weight: bold;
  }
}
</style>
